/*oE (vsechny stranky)*/
$zahlavipozadi: #F0F0F0;
$zahlavitext: #272727;
$nazev: #303030;
$dodatek: #303030;
$zahlaviodkazy: #303030;
$vyhledavaniobrys: #FF8E3D;
 
$hlavninadpisy: #303030;
 
$menupozadi: #B40D00;
$menuzvyraznenipozadi: #FFFFFF;
$menutlacitkapozadi: #FF8915;
$menutlacitkatext: #1C1C1C;
$menuzvyraznenitext: #000000;
 
$upozornenipozadi: #FF8915;
$upozorneninadpisy: #000000;
$upozornenitext: #000000;
$upozorneniodkazy: #790F01;
 
$aktuality1nadpisy: #BE0D00;
$linky: #FF8621;
$bileplochyodkazy: #DD3B00;
 
$deskalinky: #B40D00;
 
$kalendarakcipozadi: #F0F0F0;
$akcehlavninadpis: #303030;
$akcenadpisy: #303030;
$kalendarakcitext: #000000;
$akcedoplnkovytext: #000000;
$odkazyTabulekOstatni: #303030;
$odkazyTabulekAktualni: #FFFFFF;
$bunkyTabulekOstatni: #FFFFFF;
$bunkyTabulekAktualni: #B40D00;
$akcetlacitkapozadi: #FF8915;
$akcetlacitkatext: #000000;
$akceodkazy: #C8170A;
 
$formularepozadi: #FFFFFF;
$formularetext: #272727;
$formularenadpis: #303030;
$polepozadi: #FFFFFF;
$poletext: #425551;
$poleobrysy: #BE0D00;
 
$tlacitkapozadi: #FF8915;
$tlacitkatext: #000000;
 
$vysokapatapozadi: #AA0D00;
$vysokapatatext: #FFFFFF;
$vysokapataodkazy: #F0F0F0;
$vysokapatanadpisy: #FFFFFF;
$vysokapatasymboly: #FF8915;
 
$patapozadi: #F0F0F0;
$patatext: #272727;
$pataodkazy: #B40D00;
